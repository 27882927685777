a {
  text-decoration: none;
  color: $button-color;

  &:hover {
    color: $hover-link-color;
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  letter-spacing: 0.03em;
  margin: 1rem 0;

  & + .subtitle {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}

h1 {
  font-family: $font-family-headings;
  font-size: 3rem;
}

h2 {
  font-family: $font-family-headings;
  font-size: 2rem;
}

h3 {
  font-family: $font-family-body;
  font-weight: 800;
  font-size: 1.8rem;
}

h4 {
  font-family: $font-family-body;
  font-size: 1.4rem;
}

h5 {
  font-family: $font-family-body;
  font-size: 1.45rem;
}

h6 {
  font-family: $font-family-body;
  font-size: 1rem;
}

p {
  font-size: 20px;
  line-height: 1.7rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: $font-family-franklin;
}

input, ul, strong {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: $font-family-program;
}

li {
  margin-bottom: 0.5rem;
}

.capitalized {
  text-transform: none;
}

.formhead {
  font-family: $font-family-headings;
  text-transform: capitalize;
}

.centered {
  text-align: center;
}

@media screen and (min-width: $sm-breakpoint) {
  .sm-justify-left {
    text-align: left;
  }
}
