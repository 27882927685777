.announcement {
  background-color: white;
  border: 3px dashed red;
  padding: 10px;
  margin: 0;

  p:last-child {
    margin: 0;
  }
}
