#the-shows {
  @include bottom-padding;

  #shows-list {
    .show-group-featured {
      background: #facb4330;
      padding: 0 20px;
    }

    .show-group-heading h3 {
      border-bottom: 2px solid #ffff;
    }

    .show {
      margin-bottom: $margin-size * 2;
      background-color: #fff;
      border-radius: 20px 20px 20px 20px;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &.soldout {
        opacity: 0.5;
        pointer-events: none;
      }

      .show-image {
        position: relative;
        display: block;
        width: 100%;

        > img {
          width: 100%;
          border-radius: 20px 20px 0px 0px;
          height: auto;
        }
      }

      .show-time {
        display: inline;
        position: absolute;
        top: 10px;
        left: 10px;
        color: white;
        background: rgba(0, 0, 0, 0.5);
        padding: 3px;
        border-radius: 4px;
        font-family: $font-family-franklin;
        font-size: 14px;
        font-weight: bold;
      }

      .show-info {
        display: flex;
        border-radius: 0px 0px 20px 20px;
        padding: 1rem;
        font-size: 1.2em;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        color: $show-info-color;
        justify-content: space-between;
        align-items: center;

        .show-info-left {
          margin-right: $margin-size;
        }

        .show-info-right {
          flex-shrink: 0;
        }
      }

      .performer-name {
        font-family: $font-family-hoss;
        font-weight: 500;
        text-transform: uppercase;
        color: $show-info-color;
        margin: 0;
      }
    }
  }

  .find-shows {
    .inner {
      width: 100%;
      align-items: center;
      // margin-top: $margin-size * .5;
      // margin-bottom: $margin-size * 2;
      border-radius: 20px;
      padding: 1rem 0;
      box-shadow: none;

      h2 {
        font-size: 2em;
        font-weight: 500;
        letter-spacing: 0.03em;
        margin-top: .5rem;
        margin-right: 0px;
      }
      
      form {
        margin: 0;
        margin-left: none;

        .input-append, input {
          font-family: $font-family-franklin;
          margin: 0;
          border-radius: 20px;

          & + button {
            margin-top: 0;
          }
        }
      }

      @media screen and (min-width: $sm-breakpoint) {
        form {
          margin-left: $margin-size;
        }
      }  

      }
    }
  }

  @media screen and (min-width: $sm-breakpoint) {
    .find-shows {
      .inner {
        display: flex;
        justify-content: center;

        h2 {
          margin: 0;
        }
      }
    }
  }

#no-matches {
  display: none;

  p {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
}
