footer#footer {
  position: relative;
  bottom: 0;
  width: 100%;  // Set the width to 2/3 of the page width
  margin: 1rem 0 auto;  // Center the footer horizontally
  background-color: $background-color;  // Set the background color
  border-top-left-radius: 20px;  // Add rounded corners to the top-left
  border-top-right-radius: 20px; // Add rounded corners to the top-right
  color: white;
  padding: 0rem 1rem;

  a {
    color: white;
  }

  h5, .site-map-links {
    text-align: center;
  }

  #mono-logo-wrapper {
    text-align: center;
    margin-bottom: 0;  // Keeping original margin
  }

  // @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
  //   #mono-logo-wrapper {
  //     img {
  //       max-height: 50px;
  //     }
  //   }
  // }
  // @media screen and (min-width: $md-breakpoint) {
  //   #mono-logo-wrapper {
  //     img {
  //       max-height: 50px;
  //     }
  //   }
  // }
  @media screen and (max-width: $sm-breakpoint) {
    #mono-logo-wrapper {
      margin-bottom: 1rem;  // Revert to original margin
      margin-top: 1rem;
    }

    h5 {
      margin-top: 1rem;  // Adjusted top margin for site map
    }

    #subscribe-pc {
      display: block;
      position: absolute;
      top: 0;  // Position subscribe-pc above the form section
      width: 100%;  // Ensure it spans the full width
    }
  }

  #partners {
    display: flex;  // Ensures flexbox layout
    flex-direction: column;  // Aligns children vertically
    justify-content: center;  // Centers children vertically within the container
    align-content: center;  // Centers children horizontally

    h5 {
      margin: 0;
    }
  
    .partner {
      display: flex;  // Ensures flexbox layout
      justify-content: center;  // Centers the content horizontally
      align-items: center;  // Centers the content vertically
      padding-left: 5px;
      padding-right: 5px;
  
      @media screen and (max-width: $sm-breakpoint) {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
      }
  
      a {
        display: block;
        margin-right: 20px;
  
        img {
          max-height: 58px;
  
          @media screen and (max-width: $sm-breakpoint) {
            max-width: 150px;
          }
        }
  
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  
  #footer #partners .row {
    margin-right: 0;
    margin-left: 0;
    display: flex;  // Ensures flexbox layout
    // justify-content: center;  // Centers the content horizontally
    align-items: center;
  }
}  