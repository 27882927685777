#show-info {
  @include bottom-padding;

  h1 {

    margin-bottom: $margin-size*.5;

      .performer-name {
        display: block;
        font-size: 3rem;
        
      }
  }

  h3 {

    margin-top: $margin-size*.5;

      .show-name {
        display: block;
        font-weight: 460;
        font-size: 2.4rem;
        text-transform: uppercase;
      }
  }
}

#show-box {
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  font-family: $font-family-program;
}

#show-info-list {
  flex-wrap: wrap;
  font-size: 1.4rem;
  letter-spacing: 0.04em;


  span strong {
    display: inline-block;
    width: 75px;
    margin-bottom: 0;
    font-weight: 600;
  }

  li {
    width: 250px;
  }
}

@media screen and (min-width: $xs-breakpoint) {
  #show-info-list {
    li {
      width: auto;
    }
  }
}

#buy-tickets-button {
  margin-bottom: 0rem;
}

.performer-image {
  margin: 0 auto;
  margin-top: $margin-size;

  img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
}

.show-info {
  .show-title {
    font-family: $font-family-program;
  }
}

