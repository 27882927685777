@import "variables";

$input-padding-vert: 8px;

form {
  margin-top: $margin-size; // Add margin at the top of the form
  margin-bottom: 0;
}

textarea, input {
  border: 1px solid $grey-color;
  padding: $input-padding-vert 12px;
  font-size: 1.2rem;
  line-height: 1.6em;
  margin-bottom: 1rem;
  border-radius: 20px;
  position: relative;
  font-family: 'franklin-gothic-urw',sans-serif;

  &::placeholder {
    color: $input-placeholder-color;
  }
}

.form-group {
  label {
    display: block;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  input, textarea {
    display: block;
    width: 100%;
    font-family: inherit;
  }
}

.button {
  display: inline-block;
  font-family: $font-family-program;
  padding: $input-padding-vert 24px;
  text-transform: uppercase;
  border-radius: 20px;
  color: white;
  background-color: $button-color;
  font-weight: 450;
  letter-spacing: 0.04em;
  border: 0;
  cursor: pointer;

  &:disabled {
    background-color: lighten($button-color, 20%);
    cursor: default;
  }

  &:hover:not(:disabled) {
    color: $hover-link-color;
    text-decoration: none;
  }

  &:soldout {
    background-color: lighten($button-color, 20%);
    cursor: default;
  }

  &.button-full-width {
    width: 100%;
  }

  &.button-lg {
    padding: 12px $margin-size;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
  }

  &.button-sm {
    font-size: 1.2rem;
  }
}

input {
  &.input-borderless {
    border: 0;
  }

  input {
    margin-bottom: 0;
  }
}

.input-append {
  position: relative;
  display: inline-block;

  input + button {
    position: absolute;
    height: 100%;
    top: 1px;
    right: -10px;
    margin-top: -$input-padding-vert;
  }
}