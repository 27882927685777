@import "variables";
@import "grid";
@import "typography";
@import "form";
@import "header";
@import "footer";
@import "announcement";

@mixin bottom-padding {
  padding-bottom: $margin-size * 2;

  @media screen and (min-width: $md-breakpoint) {
    padding-bottom: $margin-size * 2;
  }
}

html {
  background-color: $shadow-color;
}

body {
  padding: 0;
  margin: 0;
  font-family: $font-family-franklin;
  color: $text-color;
  font-size: $font-size;
  letter-spacing: 0.04em;
}

main {
  position: relative;
  background: linear-gradient(to bottom, #ee307f 18%, #931c63);
  width: 100%;
  z-index: 0;

  // .sidegutters {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   width: 10%; // Adjust gutter width here
  //   background-color: #EE307F; // Base gutter color
  //   z-index: -1;

  //   &.left {
  //     left: 0;
  //   }

  //   &.right {
  //     right: 0;
  //   }

  //   .gutter-pattern {
  //     position: absolute;
  //     top: 0;
  //     left: 50%; // Center the images horizontally in the gutter
  //     transform: translateX(-50%); // Adjust for perfect centering
  //     width: 50%; // Adjust the size of the images
  //     height: auto;
  //     background-image: 
  //       url('/assets/images/saustamagotchi.png'), 
  //       url('/assets/images/tintamagotchi.png'); // Alternate images
  //     background-repeat: repeat-y; // Repeat vertically
  //     background-position: center top, center 10px; // Alternate positioning
  //     background-size: 50%; // Adjust the size of the images
  //     opacity: 0.2; // Set transparency for watermark effect
  //   }
  // }
}

hr {
  width: 90%;
  border: 0.5px solid $grey-color;
}

img {
  max-width: 100%;
}

ul.unstyled {
  list-style-type: none;
  padding-left: 0;
  font-family: $font-family-program;
}

.hero {
  color: white;
  // background-color: $hero-background-color;
  padding: 5px 0;

  > #hero-image {
    display: block;
    margin: 0 auto;
    max-height: 250px;
    max-width: 100%;
    height: auto;
  }

  &.hero-with-heading {
    text-align: center;

    h1, .heading {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 2rem;
      margin: 2.2rem 0;
      max-width: 500px;
      margin: 0 auto;
      padding: 30px 0;
    }

    .heading-image-home {
      display: block;
      max-height: 100px;
      // max-height: 273px;
    }

    .heading-image-home.desktop {
      display: block;
      margin: $margin-size auto;
    }

    .overlay-image {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      margin-bottom: -70px;
    }

    .heading-image-home.mobile {
      display: none;
      margin: $margin-size auto;
      padding-left: 15px;
      padding-right: 15px;
    }

    .heading-image-home.tablet {
      display: none;
      margin: $margin-size auto;
    }

    @media (max-width: $md-breakpoint) {

      .hero {
        max-width: 992px;
      }

      .heading-image-home.desktop {
        display: none;
      }

      .heading-image-home.mobile {
        display: none;
      }

      .heading-image-home.tablet {
        display: block;
      }

      .overlay-image {
        display: none;
      }
    }

    @media (max-width: $sm-breakpoint) {

      .hero {
        max-width: 768px;
      }

      .heading-image-home.desktop {
        display: none;
      }

      .heading-image-home.mobile {
        display: block;
        margin-top: 0px;
        margin-bottom: 0px;
      }

        .tam {
          max-width: 66%;
          margin: 0 auto;
        }

      .heading-image-home.tablet {
        display: none;
      }

      .overlay-image {
        display: none;
      }
    }

    .heading-image {
      display: block;
      margin: $margin-size auto;
      max-height: 85px;
    }    
  }
}

.althero {
  color: white;
  background-color: $alt-hero-background-color;
  padding: 15px 0;

  > #hero-image {
    display: block;
    margin: 0 auto;
    max-height: 250px;
    max-width: 100%;
    height: auto;
  }

  &.hero-with-heading {
    text-align: center;

    h1, .heading {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 2rem;
      margin: 2.2rem 0;
      max-width: 500px;
      margin: 0 auto;
      padding: 30px 0;
    }

    .heading-image {
      display: block;
      margin: $margin-size auto;
      max-height: 50px;
    }    
  }
}

#festival-description {
  @include bottom-padding;

  padding: $margin-size 0;
  text-align: center;

  #welcome-heading {
    font-size: 2rem;
  }
}

.find-shows {
  .inner {
    display: inline-block;
    background-color: $find-shows-background-color;
    text-align: center;
    box-shadow: 5px 5px $shadow-color;
    width: 100%;

    @media screen and (min-width: $sm-breakpoint) {
      width: auto;
      padding: 0 $margin-size;
    }

    h2 {
      color: white;
    }

    input + button {
      background: none;
      border: 0;
      outline: 0;
      vertical-align: middle;
      cursor: pointer;

      & > img {
        display: inline;
        margin-right: 14px;
        height: 22px;
        width: 22px;
      }
    }
  }
}

$featuring-height: 434px;
section#featuring {
  position: relative;
  background-color: $grey-color;
  color: white;
  text-align: center;
  height: $featuring-height;
  overflow: hidden;

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $featuring-height;

    &.animate {
      transition: margin-left 300ms ease-in-out;
    }

    .slide {
      display: block;
      height: 100%;
      width: 100vw;
      float: left;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .featuring-text {
    display: block;
    z-index: 10;
    position: relative;
    margin: 0;
    padding-top: $margin-size * 2;
    text-shadow: 0 0 4px black;
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 2rem;
    color: white;

    strong {
      font-size: 2rem;
    }
  }

  .bottom {
    position: absolute;
    width: 100%;
    bottom: $margin-size;
    z-index: 10;

    #and-many-more {
      font-weight: bold;
      font-size: 1.2rem;
      text-shadow: 0 0 3px black;
    }

    .button {
      box-shadow: 0px 4px 6px -4px black;
    }
  }

  .controls {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;

    > a {
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      padding: 0 2rem;
      pointer-events: all;

      img {
        display: block;
        position: relative;
        margin-top: ($featuring-height / 2) - (51.5/2);
      }

      &#slider-prev {
        transform: rotate(180deg);
        left: 0;
      }

      &#slider-next {
        right: 0;
      }
    }
  }
}

#join-mailing-list {
  color: white;
  font-size: 1.3em;
  letter-spacing: 0.00em;
  background-color: #147c79;
  background-size: cover;
  // padding: 2rem 2rem 0 2rem;

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .subscribe-pc {
    float: right;
  }

  @media (min-width: $md-breakpoint) {
    .container {
      padding-top: 1rem;
      justify-content: center;  // Centers children vertically within the container
    }
  }

  @media screen and (max-width: $md-breakpoint) {
    .container {
      text-align: center;
      justify-content: center;  // Centers children vertically within the container
      padding-top:15px;
    }

    .col-auto {
      width: 100%;
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
      padding-top: 2rem;
    }

    .subscribe-pc {
      float: inherit;
    }
  }
}

.centered-list {
  display: block;
  margin-top: 0;
  font-size: 1.1rem;

  li {
    display: flex;
    align-items: center;

    img {
      flex-shrink: 0;
    }

    .label {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      margin-left: 10px;

      .label-content {
        display: inline-block;
        width: 160px;
      }
    }
  }
}

@media screen and (max-width: $sm-breakpoint) {
  .centered-list {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: $margin-size*.5;
    font-size: 1rem;
  }
}

.alert {
  padding: 4px 7px;

  &-success {
    color: white;
    background-color: #14ab14;
    border: 1px solid #066f06;
  }

  &-failure {
    color: white;
    background-color: #b41313;
    border: 1px solid #5f1c1c;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

@import "pages/about";
@import "pages/contact";
@import "pages/show";
@import "pages/the-shows";
