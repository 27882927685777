#top-header {
  position: relative;
  background-color: $nav-background-color;
  padding: $margin-size*.5 0;
  overflow: hidden;
  z-index: 0;

  // Side gutters
  // &::before,
  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   width: 10%; // Adjust gutter width here
  //   background: #EE307F;
  //   z-index: -1;
  // }

  // &::before {
  //   left: 0;
  // }

  // &::after {
  //   right: 0;
  // }

  #main-logo, #navigation, #socials {
    text-align: center;
  }

    #main-logo img {
    display: block;
    height: auto;
    width: 100%;
    padding: 0 $margin-size;
    max-width: 450px;
    margin: 0 auto;
  }

  #navigation {
    font-family: $font-family-program;
    font-weight: 500;
    font-size: 2.0em;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    a {
      color: white;
      margin-right: 20px;
      padding: 1rem 0;

      &:hover {
        color: $hover-link-color;
      }

      &.active {
        color: $active-link-color;
      }
    }

    a:last-child {
      margin-right: auto;
    }
  }

  #socials {
    a {
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @media screen and (min-width: $sm-breakpoint) {
    #main-logo, #navigation {
      margin-top: 0;
      margin-bottom: 0;
    }

    #chip-mobile {
      display: none;
    }

    #socials {
      z-index: 1;
      margin-left: auto;
      margin-top: 0.5rem;
    }

    #main-logo {
      flex-shrink: 1;
    }

    #main-logo img {
      margin: 0;
      padding: 0;
      max-height: 100%;
      max-width: 100%;
    }

    #header-row {
      align-items: center;
      flex-wrap: nowrap;
    }

    #navigation {
      a {
        display: inline-block;
        padding: 6px 0;
      }
    }

    #chips {
      display: block;
      margin-top: 7px;
    }
  }

  @media screen and (max-width: 990px) {
    #chips img:first-child {
      display: none;
    }
  }
}

.header-hr {
  border: none;
  height: 8px;
  width: 100%;
  margin: 0;
  color: #fdc501;
  background-color: #fdc501;
}