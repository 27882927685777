#contact {
  @include bottom-padding;
}

#form-success, #form-failure {
  display: none;
}

.centered-list-contact {
  display: block;
  margin-top: 0;
  font-size: 1.1rem;

  li {
    display: flex;
    align-items: center;
    padding-bottom: 2.5rem;

    .sunglasses {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    .label {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      margin-left: 10px;
      font-family: $font-family-hoss;
      color: white;

      .label-content {
        display: inline-block;
        width: 160px;
      }
    }
  }
}

// @media screen & {max-width: 400px}