/* Dimensions */
$xs-breakpoint: 576px;
$sm-breakpoint: 768px;
$md-breakpoint: 992px;
$xl-breakpoint: 1200px;

/* Typography */
$font-size: 18px;
$margin-size: 1rem;
$font-family-headings: 'hoss-round-xnarrow', sans-serif;
$font-family-body: 'program-narrow', sans-serif;
$font-family-franklin: 'franklin-gothic-urw', sans-serif;
$font-family-hoss: 'hoss-round-xnarrow', sans-serif;
$font-family-program: 'program-narrow', sans-serif;

/* Colors */
$text-color: #ffff;
$secondary-text-color: #707070;
$show-info-color: #000;
$button-color: #17BDC2;
$footer-color: $button-color;
$background-color: #17BDC2;
$nav-background-color: #17BDC2;
$find-shows-background-color: #fdc501;
$hero-background-color: #17BDC2;
$alt-hero-background-color: #EE307F;
$active-link-color: #000;
$hover-link-color: #FDC501;
$footer-background-color: #F77D1D;
$input-placeholder-color: #a8a8a8;
$shadow-color: #2c2c2c;
$splash-page-color: $nav-background-color;

$grey-color: #a8a8a8;
$light-grey-color: #e3e3e3;
