.content-sections {
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;

    h2 {
      margin-top: 0;
    }

    .col-md-6 > img {
      width: 100%;
    }
  }
}

#sponsors {
  padding-top: 1rem;
  padding-bottom: 1rem;

  h2 {
    text-align: center;
    margin-bottom: $margin-size;
  }

  .sponsor {
    text-align: center;
    margin-top: $margin-size * 1;
    margin-bottom: $margin-size * 1;

    img {
      max-width: 240px;
      height: auto;
      border-radius: 0px;
    }
  }
}

#about {
  p {
    color: $text-color;
    font-size: 1.3rem;
    font-family: $font-family-franklin;
    letter-spacing: 0.00em;
  }

  img {
    border-radius: 20px;
  }

  .row:not(:last-child) {
    margin-bottom: $margin-size;
  }

  .about-hero {
    margin-bottom: $margin-size;
  }
  
  @media screen and (min-width: $md-breakpoint) {
    
    .left {
      padding-right: 2rem;
    }
  }
}
